<template>
  <article>
      <b-container fluid>
          <b-row class="mb-2">
              <b-col cols="12" sm="6" class="px-0 position-relative">
                  <p class="position-absolute w-100 h-100 d-flex align-items-center justify-content-center text-white font-weight-bolder">{{date}}</p>
                  <img :src="require('../assets/prensa/date_' + color1 + '.png')" alt="" class="img-fluid">
              </b-col>
              <b-col cols="6" sm="6" class="pt-3 pt-sm-0">
                  <h4 :style="'border-color:#' + color2" class="text-left">{{media}}</h4>
              </b-col>
          </b-row>
          <b-row>
              <b-col cols="12" sm="6" class="p-0 image-container">
                  <!-- <img :src="require('../assets/prensa/' + photo)" class="img-fluid"> -->
                  <img :src="requireImage(photo)" class="img-fluid modified-image">
              </b-col>
              <b-col cols="12" sm="6" class="p-0 pl-3 pt-3 pt-sm-0 text-left d-flex flex-column justify-content-between">
                  <div>
                    <p class="m-0"><b>{{title}}</b></p>
                    <p v-html="abstract"></p>
                  </div>
                  <div>
                      <a :href="link" target="_blank" rel="noopener noreferrer">{{button}}</a>
                  </div>
              </b-col>
          </b-row>
      </b-container>
  </article>
</template>

<script>
export default {
    name: 'Nota',
    props: ['date','media','title','abstract','photo','link','button', 'color1', 'color2'],
    methods: {
    requireImage(fileName) {
      // Check if photo is a URL
      if (this.isURL(this.photo)) {
        return this.photo; // If URL, return directly
      } else {
        // If not URL, require image from assets
        return require(`../assets/prensa/${fileName}`);
      }
    },
    isURL(str) {
      // Check if string starts with http or https
      return /^https?:\/\//.test(str);
    }
  }
}
</script>

<style scoped>
p {
    font-size: .8rem;
}

h4 {
    border-width: 0 0 7px 0;
    border-style: solid;
    font-weight: bolder;
    font-size: 1.3rem;
}

.image-container {
    position: relative;
    height: 200px; /* Ajusta esta altura según lo necesario */
    display: flex;
    align-items: flex-start;
    justify-content: center;
}

img.modified-image {
    max-height: 100%;
    max-width: 100%;
    object-fit: cover;
}
</style>
