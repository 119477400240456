<template>
  <div>
    <header>
      <h1 class="main-title">{{$tc('press.title')}}</h1>
    </header>
    <div class="desc position-relative">
      <h2>
        {{$tc('press.desc')}}
      </h2>
      <img
        class="position-absolute h-75 d-none d-md-block"
        style="left: 0; top: 10%"
        src="@/assets/prensa/u.png"
        alt=""
      />
    </div>
    <section>
      <div class="media position-relative text-white">
        <h2
          class="p-0 m-0 position-absolute w-100 h-100 d-flex align-items-center justify-content-center font-weight-bolder"
        >
          {{$tc('press.title2',1)}} <br />
          {{$tc('press.title2',2)}}
        </h2>
        <img src="@/assets/prensa/media.jpg" alt="media" class="img-fluid" />
      </div>
      <b-container class="mt-5">
        <b-row>
          <b-col v-for="(nota, index) in notas" :key="index" cols="12" lg="6" class="py-4">
            <Nota 
                :date="nota.date" 
                :media="nota.media"
                :title="lang == 'es' ? nota.titleES : nota.titleEN"
                :abstract="lang == 'es' ? nota.abstractES : nota.abstractEN"
                :button="$tc('press.' + nota.type)"
                :photo="nota.photo"
                :link="nota.link"
                :color1="nota.type == 'note' ? '0000ff' : nota.type == 'interview' ? 'ff00ff' : 'baff00'"
                :color2="nota.type == 'note' ? 'ff00ff' : nota.type == 'interview' ? 'baff00' : '0000ff'"
                />
          </b-col>

          
        </b-row>
      </b-container>
    </section>
  </div>
</template>

<script>
import Nota from "@/components/nota";
import { i18n } from '@/plugins/i18n';
import {mapMutations} from 'vuex';

export default {
  name: "Prensa",
  components: {
    Nota,
  },
  data(){
      return {
          notas: [
              {
                date:"11/10/2023" ,
                media:"Punto Biz",
                titleES:'Cinco tips para conseguir un nuevo empleo.',
                titleEN: 'Five tips to get a new job.',
                abstractES:"Yanina Da Luz, Head Of Talent de MindHub, nos comparte tips fundamentales si estás buscando trabajo en el sector tech. ",
                abstractEN: 'Yanina Da Luz, Head Of Talent at MindHub, shares some fundamental tips if you are looking for a job in the tech sector. ',
                type: 'note',
                photo:"https://media.cdn.puntobiz.com.ar/102023/1697049887927.webp?cw=807&ch=454&extw=jpg",
                link:"https://puntobiz.com.ar/gestion/cinco-tips-para-conseguir-un-nuevo-empleo-2023101115380"
              },
              {
                date:"14/07/2023" ,
                media:"Buenos Aires Ciudad",
                titleES:'Los egresados de Codo a Codo ingresan a becas para capacitarse.',
                titleEN: 'The Codo a Codo graduates receive scholarships for training.',
                abstractES:"Mayores oportunidades de acceso a propuestas formativas intensivas y actualizadas del mundo tecnológico.",
                abstractEN: 'Increases in access opportunities to intensive and updated training proposals in the technological world.',
                type: 'note',
                photo:"https://buenosaires.gob.ar/sites/default/files/styles/full_width/public/2023-07/AR__7376.jpg?itok=YyFXwoQe",
                link:"https://buenosaires.gob.ar/noticias/los-egresados-de-codo-codo-ingresan-becas-para-capacitarse"
              },
              {
                date:"12/07/2023" ,
                media:"MinTIC",
                titleES:'MinTIC firma acuerdo con Rappi para formar en tecnología a domiciliarias.',
                titleEN: 'MinTIC signs agreement with Rappi to provide technology training to home-based businesses.',
                abstractES:"MindHub realizará la formación mientras que Rappi acompañará con mentorías. Además se dará un respaldo a las participantes en la búsqueda de oportunidades laborales…",
                abstractEN: 'MindHub will provide the training while Rappi will provide mentoring. In addition, participants will be supported in their search for job opportunities...',
                type: 'note',
                photo:"https://files.rcnradio.com/public/styles/d_img_850x580/public/2019-09/000_19z3cm_7.jpg.webp?VersionId=WLdx22wGJJAPRBZkMLLmLl7rgTQ_M._K&itok=yvpFVtH0",
                link:"https://www.rcnradio.com/estilo-de-vida/educacion/mintic-firma-acuerdo-con-rappi-para-formar-en-tecnologia-a-domiciliarias"
              },
              {
                date:"30/05/2023" ,
                media:"El Cronista",
                titleES:'Saltar sin red: las chicas se animan a ser líderes de sus negocios.',
                titleEN: 'Jumping without a net: girls are encouraged to be leaders in their businesses.',
                abstractES:"El mundo de la inversión está muy ligado a emprendimientos de base tecnológica.",
                abstractEN: 'The investment world is closely linked to technology-based ventures.',
                type: 'note',
                photo:"ohlala.jpg",
                link:"https://www.cronista.com/negocios/saltar-sin-red-las-chicas-se-animan-a-ser-lideres-de-sus-negocios/"
              },
              {
                date:"08/02/2023" ,
                media:"Ámbito",
                titleES:'MindHub se suma para aportar su granito al proyecto "Nosotras Conectadas".',
                titleEN: 'MindHub joins to contribute to the "Nosotras Conectadas" project.',
                abstractES:"La startup nacional brindó su aporte, llevando adelante un programa intensivo de Desarrollo Web dirigido a 15 mujeres.",
                abstractEN: 'The national startup made its contribution by carrying out an intensive Web Development program aimed at 15 women.',
                type: 'note',
                photo:"iproup.jpg",
                link:"https://www.ambito.com/mindhub-se-suma-la-direccion-colectividades-del-gobierno-la-ciudad-aportar-su-granito-al-proyecto-nosotras-conectadas-n5646955"
              },
              {
                date:"15/11/2022",
                media:"La Tercera",
                titleES:"Centennials eligen carreras cortas, intensivas y con rápida inserción laboral",
                titleEN:'Centennials choose short, intensive careers with rapid job placement',
                abstractES:"Los Bootcamps aparecen como la mejor alternativa para formarse e insertarse rápidamente en el mercado…",
                abstractEN: 'Bootcamps appear to be the best alternative for training and rapid insertion in the market...',
                type: 'note',
                photo:"Logolatercera2017.png",
                link:"https://www.latercera.com/que-pasa/noticia/centennials-eligen-carreras-cortas-intensivas-y-con-rapida-insercion-laboral/",
              },
              {
                date:"17/07/2022",
                media:"iprofesional",
                titleES:"Talento digital: conocé la historia de tres mujeres que hicieron de la escasez de profesionales un gran negocio.",
                titleEN: 'Digital talent: learn the story of three women who turned the shortage of professionals into a great business.',
                abstractES:"Cuando se habla sobre la falta de colaboradoras dentro de la industria IT, ellas se animaron a meterse de lleno…",
                abstractEN: 'When people talk about the lack of female collaborators in the IT industry, they were encouraged to get involved in...',
                type: 'note',
                photo:"https://resizer.iproimg.com/unsafe/1280x/filters:format(webp)/https://assets.iprofesional.com/assets/jpg/2022/07/539167.jpg",
                link:"https://www.iprofesional.com/negocios/365841-talento-digital-tres-mujeres-argentina-y-un-gran-negocio",
              },
              {
                date:"22/06/2022" ,
                media:"InfoNegocios",
                titleES:"MindHub, la startup fundada por mujeres que capacita futuros programadores simulando entornos laborales.",
                titleEN: 'MindHub, the startup founded by women that trains future programmers by simulating work environments.',
                abstractES:"La principal característica de MindHub es el modo de aprender…",
                abstractEN: 'The main feature of MindHub is the way of learning...',
                type: 'note',
                photo:"https://infonegocios.info/content/companies/logos/35143_info_negocios_logo_nvo.jpg",
                link:"https://infonegocios.info/enfoque/mindhub-la-startup-fundada-por-mujeres-que-capacita-futuros-programadores-simulando"
              },
              {
                date:"13/06/2022" ,
                media:"Perfil",
                titleES:"Un trabajador del sector IT puede sacar a su familia de la pobreza.",
                titleEN: 'An IT worker can lift his or her family out of poverty.',
                abstractES:"“La tecnología funciona como un ascensor social. Una persona con un par de años en la industria saca de la línea de pobreza a toda su familia.” Vanessa Taiah",
                abstractEN: '"Technology works as a social elevator. One person with a couple of years in the industry lifts their entire family out of the poverty line." Vanessa Taiah',
                type: 'note',
                photo:"https://fotos.perfil.com/2022/06/08/trim/987/555/trabajadores-del-sector-it-o-software-20220608-1369146.jpg?webp",
                link:"https://www.perfil.com/noticias/economia/un-trabajador-del-sector-it-puede-sacar-a-su-familia-de-la-pobreza.phtml"
              },
              {
                date:"21/05/2022" ,
                media:"El Mostrador",
                titleES:"En Chile el 31% de las vacantes de empleo en tecnología no están cubiertas por falta de profesionales.",
                titleEN: 'In Chile, 31% of job vacancies in technology are not filled due to a lack of professionals.',
                abstractES:"Estos bootcamps proponen prácticas para llevar a los alumnos de 0 a 100...",
                abstractEN: 'These bootcamps offer internships to take students from 0 to 100...',
                type: 'note',
                photo:"https://media-front.elmostrador.cl/2017/03/nuevas_tecnologias.gif",
                link:"https://www.elmostrador.cl/agenda-pais/2022/05/21/en-chile-el-31-de-las-vacantes-de-empleo-en-tecnologia-no-estan-cubiertas-por-falta-de-profesionales/"
              },
          ]
      }
  },
  computed: {
      lang(){
            return i18n.locale
        }
  },
  methods: {
    ...mapMutations(['styleNav'])
  },
  beforeMount(){
    this.styleNav({type: 'dark', variant: 'blue', logo: 'logo_blanco.webp'})
  }
};
</script>

<style scoped>
header {
  background-image: url("../assets/prensa/header_prensa.jpg");
  background-size: cover;
  padding: 15% 0 30% 0;
  color: #baff43;
}

h2{
  font-size: 1.5rem;
}

.desc{
  padding: 1em;
  text-align: center;
}

@media screen and (max-width: 425px){
  header{
    margin-top: 0;
  }

  h2{
    font-size: 1.2rem;
  }
}

@media screen and (min-width: 768px) {
    .desc {
      padding: 3em 3em 3em 15em;
      text-align: left;
    }

    h2{
      font-size: 2rem;
    }
}

@media screen and (min-width: 992px) {
    .desc {
      padding: 4em 4em 4em 15em;
      text-align: left;
    }

    h2{
      font-size: 2.5rem;
    }
}

@media screen and (min-width: 1200px) {
    .desc {
      padding: 5em 5em 5em 15em;
      text-align: left;
    }

    h2{
      font-size: 2.5rem;
    }
}


</style>